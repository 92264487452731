import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 获取商品列表
export const getList = (data, pageNum, pageSize) => {
    data['pageNum'] = pageNum - 1
    data['pageSize'] = pageSize
    return fetchApi(URL.GET_INTEGRAL_RULE_LIST, data, 'post', 'data', true)
}

// 新增
export const insertIntegraRule = (data) => {
    return fetchApi(URL.INSERT_INTEGRAL_RULE, data, "post", 'data');
}

// 查询一条
export const findOneById = (id) => {
    return fetchApi(URL.FIND_ONE_RULE_BY_ID + '/' + id,'',"get", "params" )
}

// 启用禁用
export const toEnabledAndDisabledRule = (id, status) => {
    return fetchApi(URL.TO_ENABLED_AND_DISABLED_RYLE + '/' + id + '/' + status, '','put', 'params');
}

// 删除规则
export const toDeleteRuleById = (id) => {
    return fetchApi(URL.DELETE_RULE_BY_ID + '/' + id,'','delete','params')
}

// 修改规则
export const updateIntegraRule = (data) => {
    return fetchApi(URL.UPDATE_INTEGRAL_RULE_BY_ID, data, 'put','params')
}