<template>
  <div>
    <ds-header :title="title"></ds-header>
    <div>
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="ruleName" label="积分规则名称" prop="ruleName">
          <a-input
              :disabled="isView"
              v-model="form.ruleName"
              @blur="
          () => {
            $refs.ruleName.onFieldBlur();
          }
        "
          />
        </a-form-model-item>
        <a-form-model-item label="适用项目" prop="projectIds">
          <a-select :disabled="isView" mode="multiple" v-model="form.projectIds" placeholder="请选择适用项目">
            <a-select-option v-for="item in projects" :key="item.id">
              {{ item.project_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="富文本">
          <div id="editer"></div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button v-if="!isView" type="primary" @click="onSubmit">
            保存
          </a-button>
          <a-button style="margin-left: 10px;" @click="resetForm">
            {{ isView ? '返回' : '取消' }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { projectName } from '@/api/equityManagement'
import { insertIntegraRule, findOneById,updateIntegraRule } from "@/api/integralRule"
import wangEditor from 'wangeditor'
import {IMG_API} from '@/config';
import {mapState} from "vuex";
export default {
  name: 'addIntegralRule',
  computed: {
    ...mapState({
      projects: (state) => state.common.projectList
    }),
  },
  data() {
    return {
      IMG_API: IMG_API,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      title:'添加积分规则',
      isView: false,
      form: {
        id: "",
        ruleName: '',
        ruleContent: '',
        projectIds: []
      },
      rules: {
        ruleName: [
          { required: true, message: '请输入积分规则名称', trigger: 'blur' },
          { min: 1, max: 20, message: '积分规则名称在1～20字符以内', trigger: 'blur' },
        ],
        projectIds: [
          { required: true, message: '请选择适用项目', trigger: 'change' },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.type === 'view') {
      this.isView = true;
      this.title = '查看积分规则'
      this.findOne();
    }
    if (this.$route.query.type === 'edit') {
      this.title = '编辑积分规则'
      this.findOne();
    }
  },
  mounted() {
    this.createEditor()
  },
  methods: {
    // 查询一条
    findOne() {
      findOneById(this.$route.query.id).then(res => {
        if (res.code === '200') {
          this.form.id = res.data.id
          this.form.ruleName = res.data.ruleName
          this.form.projectIds = res.data.projectIds
          this.form.ruleContent = res.data.ruleContent
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onSubmit() {
      console.log(this.form)
      let that = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$route.query.type === 'add') {
            insertIntegraRule(this.form).then(res => {
              if (res.code === "200") {
                that.$router.go(-1);
                that.$message.success(res.msg);
              } else {
                that.$message.warn(res.msg)
              }
            })
          } else {
            updateIntegraRule(this.form).then(res => {
              if (res.code === '200') {
                this.$router.go(-1)
                that.$message.success("操作成功")
              } else {
                this.$message.warn(res.msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.go(-1);
    },
    createEditor () {
      const _this = this
      const editor = new wangEditor(`#editer`)
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.form.ruleContent = newHtml
      }
      editor.zIndex.baseZIndex = 100
      editor.config.menus = [
        'fontName',
        'fontSize',
        'foreColor',
        'backColor',
        'underline',
        'italic',
        'bold',
        'justify',
        'splitLine',
        'undo',
        'redo',
        'list',
        'table',
        'image',
        'video',
      ]
      editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
      editor.config.uploadImgServer = this.IMG_API + '/oss/files'
      editor.config.uploadImgShowBase64 = false
      editor.config.showLinkImg = false
      editor.config.showLinkVideo = false
      editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
      editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图
      // editor.config.uploadVideoAccept = ['mp4']
      editor.config.uploadFileName = 'file'
      editor.config.uploadVideoName = 'file'
      editor.config.uploadVideoTimeout = 60000
      editor.config.uploadImgTimeout = 60000
      editor.config.uploadVideoHooks = {
        // 上传视频之前
        before: function(xhr, editor, files) {
          console.log(xhr)
          console.log(editor)
          console.log(files)
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error('上传请求超时')
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
          editor.cmd.do('insertHTML', videoHTML);
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          // insertVideoFn(result.redirect_uri)
        }
      }
      editor.config.uploadImgHooks = {
        // 上传视频之前
        before: function(xhr) {
        },
        // 视频上传并返回了结果，视频插入已成功
        success: function(xhr) {
          // this.$message.success('上传视频成功')
        },
        // 视频上传并返回了结果，但视频插入时出错了
        fail: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          _this.$message.error('上传请求失败')
        },
        // 上传视频超时
        timeout: function(xhr) {
          _this.$message.error('上传请求超时')
        },
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function(insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.redirect_uri)
        }
      }
      editor.config.customAlert = function (s, t) {
        switch (t) {
          case 'success':
            _this.$message.success(s)
            break
          case 'info':
            _this.$message.info(s)
            break
          case 'warning':
            _this.$message.warning(s)
            break
          case 'error':
            _this.$message.error(s)
            break
          default:
            _this.$message.info(s)
            break
        }
      }
      editor.config.placeholder = '请输入积分规则'
      // 创建编辑器
      editor.create()
      if (this.isView) {
        editor.disable()
      }
      this.editor = editor
      setTimeout(() => {
        this.editor.txt.html(this.form.ruleContent)
      },100);
    },
  },
};
</script>

<style scoped>

</style>